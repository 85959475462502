@import "src/ui-kit/styles/vars";

.collateral-chart {
  width: 500px;
  margin-bottom: 40px;

  &__content {
    display: flex;
    justify-content: space-between;
  }

  &__legend {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 30px 8px 30px 0;
    flex: 1;

    font-weight: 500;
    font-size: 14px;
    line-height: 18px;

    &__name {
      margin: 8px 8px 0 0;
      width: 215px;
    }
  }

  @media only screen and (max-width: $mobile-width) {
    margin-bottom: 16px;
    &__legend {
      padding: 16px 8px 0 0;

      &__name {
        width: 100%;
      }
    }
  }
}