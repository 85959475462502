@import "../../styles/vars";

$border: 1px;

.button-gradient {
  font-family: 'DM Sans', sans-serif;
  height: 58px;
  cursor: pointer;

  padding: 5px;
  box-sizing: border-box;

  font-weight: 700;
  font-size: 14px;
  border: 5px solid $black;
  line-height: 18px;
  background: $gradient;

  &:hover {
    background: $dark-gray;

    .button-gradient__text {
      background: $gradient;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .button-gradient__content {
      background: $dark-gray;
    }
  }

  &__text {
    user-select: none;
    transition: all 200ms;
    color: $black;
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    transition: all 200ms;
    border-radius: 80px;

    width: 100%;
    height: 100%;
    background: $gradient;
  }

  &--disabled {
    pointer-events: none;
    cursor: not-allowed;
    user-select: none;

    .button-gradient__text {
      opacity: 0.5;
    }
  }

  &--loading {
    animation: gradient-loader 2s ease infinite;
    background-size: 200% 100%!important;
  }
}

@keyframes gradient-loader {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}