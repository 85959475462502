@import "../../styles/vars";

$border: 1px;

.gradient-border {
  position: relative;
  width: fit-content;
  height: fit-content;
  border: solid $border transparent;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: -1;
    margin: calc(-#{$border} - 0px);
    overflow: hidden;
    border-radius: inherit;
    background: linear-gradient(74.43deg, #DBB0FF -12.43%, #C2DCFF 24.51%, #B4FFE0 63.46%, #FEFBDA 82.01%, #FECBFF 110.47%);
  }
}
