@import "../../styles/vars";

.back-button {
  position: absolute;

  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
  user-select: none;
  transition: opacity 150ms;

  &:hover {
    opacity: 0.7;
  }

  &--disabled {
    pointer-events: none;
    cursor: not-allowed;
    color: rgba(255,255,255, 0.5);
  }

  &__text {
    margin-left: 8px;
  }
}
