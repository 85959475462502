@import "../../../../ui-kit/styles/vars";

.vesting-terms {
  width: 50%;
  margin: 0 0 40px 10px;

  &__content {
    display: flex;
    justify-content: space-between;
  }

  &__list {
    padding: 15px 0 0 30px;
    margin: 0;
  }

  @media only screen and (max-width: $tablet-width) {
    margin: 20px auto !important;
  }
}