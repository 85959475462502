@import "src/ui-kit/styles/vars";

.donate-container {
    margin: 20px 50px;
    width: 480px;
}

.donate {
    text-align: center;
    padding: 24px 30px;
    box-sizing: border-box;
    background: $black;
    border-radius: 24px;
    position: relative;

    &__section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: white;
        padding-bottom: 12px;

        &__title {
            display: flex;
            align-items: center;

            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            padding-right: 12px;
        }

        &__description {
            font-weight: 700;
            font-size: 20px;
            line-height: 26px;

            &--large {
                font-weight: 500;
                font-size: 32px;
                line-height: 42px;
                color: $gray1;
                overflow: hidden;
                text-overflow: ellipsis;
                height: 42px;
            }
        }
    }

    &__subtitle {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        padding-bottom: 30px;

        &--red {
            padding-top: 10px;
            color: $red;
        }
    }

    &__progress-bar {
        margin: 8px;
        flex: 1;

        &__title {
            font-weight: bold;
            padding-left: 8px;
            text-align: left;
        }
        &__description {
            font-weight: bold;
        }
    }

    &__token-input {
        margin: 35px 0 4px 0;
    }

    &__button {
        flex: 1;

        &-container {
            width: calc(100% - 16px);
            margin: 15px 8px 0 8px;

            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }

    @media only screen and (max-width: $mobile-width) {
        padding: 15px 8px;
        width: 100%;
    }
}