.profile-tokenomics {
    max-width: 1400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__title {
        text-align: center;
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        color: white;
        padding-bottom: 24px;
    }
}