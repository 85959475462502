@import "../../../../ui-kit/styles/vars";

.reward-chart {
  width: 700px;
  margin: 40px 0;

  &__navbar {
    display: flex;

    &__title {
      font-size: 18px;
      line-height: 20px;

      height: 40px;
      padding: 8px 10px;
      border-radius: 80px;
      border: 2px solid transparent;
      margin-right: 4px;
      box-sizing: border-box;

      cursor: pointer;
      transition: all 200ms;

      &--selected {
        border: 2px solid $dark2;
        background: none !important;
        color: white;
        -webkit-text-fill-color: white !important;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__content {
    width: 700px;
    margin-top: 20px;
    position: relative;

    &__chart {
      display: none;
      margin-left: -50px;

      &--visible {
        display: block;
      }
    }

    .recharts-label {
      padding-top: 10px;
      fill: white;
    }

    .recharts-surface {
      overflow: visible;
    }
  }

  @media only screen and (max-width: $mobile-width) {
    margin-bottom: 16px;
  }
}

.custom-tooltip {
  background: $dark;
  padding: 10px;
  border-radius: 8px;
}