@import "src/ui-kit/styles/vars";

.info-container {
  background: $dark-gray;
  border-radius: 16px;
  padding: 24px;
  color: #FFFFFF;
  flex: 1;
  margin-right: 16px;
  position: relative;
  margin-top: 16px;

  &:last-child {
    margin-right: 0;
  }

  &__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
  }

  @media (max-width: $mobile-width) {
    margin-right: 0;
    padding: 12px;
  }
}